import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { restClient } from "../axiosInit";

export type Node = {
  public_key: string;
  last_active_date: string;
  ranking: number;
  last_updated_at: string;
};

const getNodes = async (): Promise<{ data: Node[] }> => {
  return await restClient.get("https://socialchainapp.com/block_explorer/mainnet_node_selection_ranking");
};

export const useGetNodes = () => {
  const [nodes, setNodes] = useState<Node[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const fetchedNodes = await getNodes();

        if (fetchedNodes.data) {
          setNodes(fetchedNodes.data);
        }
      } catch (e: any) {
        setFetchingError({ title: t("fetching.error"), error: e });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [t]);

  return { nodes, isLoading, fetchingError };
};
