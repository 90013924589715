export const formatDateAndTime = (dateString?: string, hideTime?: boolean) => {
  if (!dateString) return;

  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes();

  if (hideTime) {
    return `${month}/${day}/${year}`;
  }

  return `${month}/${day}/${year} ${hours}:${minutes}`;
};
