import React from "react";
import { useTranslation } from "react-i18next";

import LoaderWrapper from "../../components/LoaderWrapper/LoaderWrapper";
import {
  ListContainer,
  ListRow,
  MainContainer,
  TableHeader,
  TableTitles,
  Title,
} from "../../components/ReusableStyles";
import { useGetNodes } from "../../data/services/useGetNodes";
import { formatDateAndTime } from "../../utils/formatDateAndTIme";
import * as S from "./Nodes.styles";
import { LastActiveDateWrapper, PublicKeyWrapper, RankWrapper } from "./Nodes.styles";

const Nodes = () => {
  const { nodes, isLoading, fetchingError } = useGetNodes();

  const { t } = useTranslation();

  if (!nodes || isLoading || fetchingError)
    return <LoaderWrapper loading={isLoading} fetchingError={fetchingError} />;

  return (
    <MainContainer>
      <TableHeader>
        <Title>{t("nodes").toUpperCase()}</Title>
      </TableHeader>
      <TableTitles>
        <S.Rank>{t("nodes.rank")}</S.Rank>
        <S.LastActiveDate>{t("nodes.last-active-date")}</S.LastActiveDate>
        <S.PublicKey>{t("nodes.public-key")}</S.PublicKey>
      </TableTitles>
      <ListContainer>
        {nodes.map((node, index) => (
          <ListRow $isLast={index === (nodes?.length || 0) - 1} key={node.public_key + index}>
            <RankWrapper>{node.ranking}</RankWrapper>
            <LastActiveDateWrapper>{formatDateAndTime(node.last_active_date, true)}</LastActiveDateWrapper>
            <PublicKeyWrapper>{node.public_key}</PublicKeyWrapper>
          </ListRow>
        ))}
      </ListContainer>
    </MainContainer>
  );
};

export default Nodes;
