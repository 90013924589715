import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JsonFormatter from "react-json-formatter";
import { useParams } from "react-router-dom";

import { restClient } from "../../data/axiosInit";
import CopyIconButton from "../CopyIconButton/CopyIconButton";
import SpringModal from "../CustomModal/CustomModal";
import { CopyButtonPositioner, CustomButton, JsonPositioner, JsonWrapper, Source } from "./JSONButton.styles";

const jsonStyle = {
  propertyStyle: { color: "red" },
  stringStyle: { color: "green" },
  numberStyle: { color: "darkorange" },
  braceStyle: { color: "white" },
  style: { padding: "10px 10px 30px 20px" },
};

type JsonButtonProps = {
  type?: "transactions" | "ledgers" | "accounts" | "operations";
  recordId?: string;
};

const JsonButton = (p: JsonButtonProps) => {
  const [isJsonOpen, setIsJsonOpen] = useState(false);
  const [json, setJson] = useState<any>();

  const { t } = useTranslation();
  const { environment } = useParams<{ environment: string }>();

  useEffect(() => {
    if (!environment || !p.type || !p.recordId || !isJsonOpen) return;

    (async () => {
      try {
        const fetchedJson = await restClient.get(
          `https://api.${environment}.minepi.com/${p.type}/${p.recordId}`
        );

        setJson(fetchedJson.data);
      } catch {
        console.log("ERROR");
      }
    })();
  }, [environment, isJsonOpen, p.recordId, p.type]);

  return (
    <>
      <CustomButton onClick={() => setIsJsonOpen(true)}>{t("json").toUpperCase()}</CustomButton>
      <SpringModal isOpen={isJsonOpen} handleClose={() => setIsJsonOpen(false)}>
        <Source>
          {t("source.account")}:{" "}
          <a
            href={`https://api.${environment}.minepi.com/${p.type}/${p.recordId}`}
            target="_blank"
            rel="noreferrer"
          >
            {`https://api.${environment}.minepi.com/${p.type}/${p.recordId}`}
          </a>
        </Source>
        <JsonWrapper>
          <JsonPositioner>
            <JsonFormatter json={json} tabWith={4} jsonStyle={jsonStyle} />
          </JsonPositioner>
          <CopyButtonPositioner>
            <CopyIconButton value={JSON.stringify(json, null, 4)} copiedInfoOnLeft bigSize />
          </CopyButtonPositioner>
        </JsonWrapper>
      </SpringModal>
    </>
  );
};

export default JsonButton;
