import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { Tab, TabsContainer } from "./HeaderTabs.styles";

const HeaderTabs = () => {
  const { environment } = useParams<{ environment: string }>();

  const location = useLocation();
  const { t } = useTranslation();

  return (
    <TabsContainer>
      <Tab
        to={`/${environment}/operations`}
        $active={
          location.pathname === "/mainnet/operations" ||
          location.pathname === "/testnet/operations" ||
          location.pathname === "/testnet2/operations"
        }
      >
        {t("operations").toUpperCase()}
      </Tab>
      <Tab
        to={`/${environment}/payments`}
        $active={
          location.pathname === "/mainnet/payments" ||
          location.pathname === "/testnet/payments" ||
          location.pathname === "/testnet2/payments"
        }
      >
        {t("payments").toUpperCase()}
      </Tab>
      <Tab
        to={`/${environment}/transactions`}
        $active={
          location.pathname === "/mainnet/transactions" ||
          location.pathname === "/testnet/transactions" ||
          location.pathname === "/testnet2/transactions"
        }
      >
        {t("transactions").toUpperCase()}
      </Tab>
      <Tab
        to={`/${environment}/blocks`}
        $active={
          location.pathname === "/mainnet/blocks" ||
          location.pathname === "/testnet/blocks" ||
          location.pathname === "/testnet2/blocks"
        }
      >
        {t("ledgers").toUpperCase()}
      </Tab>
      <Tab
        to={`/${environment}/nodes`}
        $active={
          location.pathname === "/mainnet/nodes" ||
          location.pathname === "/testnet/nodes" ||
          location.pathname === "/testnet2/nodes"
        }
      >
        {t("nodes").toUpperCase()}
      </Tab>
    </TabsContainer>
  );
};

export default HeaderTabs;
