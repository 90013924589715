import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
  useLocation,
  useParams,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Layout from "./components/Layout/Layout";
import { StellarContextProvider } from "./contexts/StellarContextProvider";
import Account from "./pages/Account/Account";
import Block from "./pages/Block";
import Blocks from "./pages/Blocks";
import Home from "./pages/Home/Home";
import Nodes from "./pages/Nodes/Nodes";
import Operations from "./pages/Operations";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Payments from "./pages/Payments";
import Transaction from "./pages/Transaction";
import Transactions from "./pages/Transactions";
import { theme } from "./theme";

const EnvironmentWrapper = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation();

  if (!pathname.includes("/mainnet") && !pathname.includes("/testnet") && !pathname.includes("/testnet2")) {
    return <Navigate to={`/mainnet${pathname}`} />;
  }

  return children;
};

type RedirectType = "transactions" | "transactionId" | "blockId" | "accountId";

const RedirectFromDeprecatedRoutes = ({ type }: { type: RedirectType }) => {
  const { environment, blockId, transactionId, accountId } = useParams<{
    environment: string;
    blockId: string;
    transactionId: string;
    accountId: string;
  }>();

  // Redirect to the correct path with the current environment and additional param if present
  if (type === "transactionId")
    return <Navigate to={`/${environment}/transactions/${transactionId}`} replace={true} />;
  if (type === "transactions") return <Navigate to={`/${environment}/transactions`} replace={true} />;
  if (type === "blockId") return <Navigate to={`/${environment}/blocks/${blockId}`} replace={true} />;
  if (type === "accountId") return <Navigate to={`/${environment}/accounts/${accountId}`} replace={true} />;

  return <Navigate to={"/"} replace={true} />;
};

const Router = () => {
  const routes: RouteObject[] = [
    {
      path: "/",
      element: (
        <EnvironmentWrapper>
          <Home />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment",
      element: (
        <EnvironmentWrapper>
          <Home />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/operations",
      element: (
        <EnvironmentWrapper>
          <Operations />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/payments",
      element: (
        <EnvironmentWrapper>
          <Payments />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/transactions/:transactionId",
      element: (
        <EnvironmentWrapper>
          <Transaction />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/tx/:transactionId",
      element: <RedirectFromDeprecatedRoutes type="transactionId" />,
    },
    {
      path: "/:environment/transactions",
      element: (
        <EnvironmentWrapper>
          <Transactions />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/tsx",
      element: <RedirectFromDeprecatedRoutes type="transactions" />,
    },
    {
      path: "/:environment/blocks",
      element: (
        <EnvironmentWrapper>
          <Blocks />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/blocks/:blockId",
      element: (
        <EnvironmentWrapper>
          <Block />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/block/:blockId",
      element: <RedirectFromDeprecatedRoutes type="blockId" />,
    },
    {
      path: "/:environment/accounts/:accountId",
      element: (
        <EnvironmentWrapper>
          <Account />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/account/:accountId",
      element: <RedirectFromDeprecatedRoutes type="accountId" />,
    },
    {
      path: "/:environment/*",
      element: (
        <EnvironmentWrapper>
          <PageNotFound />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/:environment/nodes",
      element: (
        <EnvironmentWrapper>
          <Nodes />
        </EnvironmentWrapper>
      ),
    },
    {
      path: "/*",
      element: (
        <EnvironmentWrapper>
          <PageNotFound />
        </EnvironmentWrapper>
      ),
    },
  ];

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <StellarContextProvider>
          <ThemeProvider theme={theme}>
            <Layout />
          </ThemeProvider>
        </StellarContextProvider>
      ),
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
