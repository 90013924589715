import styled from "styled-components";

export const Rank = styled.p`
  min-width: 80px;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const PublicKey = styled(Rank)`
  flex: 1;
`;

export const LastActiveDate = styled(Rank)`
  min-width: 200px;
`;

export const RankWrapper = styled.p`
  min-width: 80px;
`;

export const PublicKeyWrapper = styled(RankWrapper)`
  flex: 1;
`;

export const LastActiveDateWrapper = styled(RankWrapper)`
  min-width: 200px;
`;
